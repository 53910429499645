import map from "@components/map";
import { checkIfVarExist, select, selectAll } from "@utils/function";
import ShowHidePlacesOnMap from "./showHidePlacesOnMap";
import AddTwitsToMap from "./addTwitsToMap";
import AnalyticsTracking from "@helpers/analyticsTracking";

/**
 * Show player popup on id
 *
 * open popup on locator site
 * all places have place-id - id is a number from DB
 */
export default function ShowPlacePopup({
  placeID,
  currentZoom,
  clickType,
  partner,
} = {}) {
  // check twits in Highlights
  const dataTwits = select(`[data-twits="${placeID}"]`);
  if (dataTwits?.dataset.showOnMap === "false") {
    AddTwitsToMap({ dataTwitsID: placeID });
  }

  if (placeID) {
    const zoom = Math.floor(currentZoom);
    // setView
    HQ.Properties.places.map(({ id, latitude, longitude, zoom_levels }) => {
      if (Number(id) === Number(placeID)) {
        const firstZoom = [...zoom_levels].shift();

        if (zoom < firstZoom) {
          if (latitude) {
            // map.setZoom(firstZoom);
            ShowHidePlacesOnMap(firstZoom);
            map.setView([latitude, longitude], firstZoom, { animation: true });
          }
        }
      }
    });

    // click on specific checkbox in information slider
    const dataChildren = selectAll(".information-row");
    [...dataChildren].map((child) => {
      if (!checkIfVarExist(window[`marker-${placeID}`])) return;

      const childrenArray = child.dataset.children.split(",");
      const check = childrenArray.includes(placeID);

      if (check) {
        const checkPlaceOnID = map.hasLayer(window[`marker-${placeID}`]);
        const dataLayerId = child.dataset.layerId;

        if (!checkPlaceOnID) {
          const informationCheckbox = select(
            `[data-layer-id="${dataLayerId}"]`
          );
          informationCheckbox?.click();
        }
      }
    });

    const findPlaceByID = HQ.Properties.places
      .filter((c) => c.id == placeID)
      .map((el) => el)
      .pop();

    const { icon, name_tracking } = findPlaceByID || {};
    const showPlaceParnter = partner ? partner : "";

    let checkPlayes = false;
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        if (
          layer.options.icon.options.className.search(`place-${placeID}`) !==
            -1 &&
          !checkPlayes
        ) {
          const partnerName = layer.options.icon.options.data;

          const partner = partnerName.hasOwnProperty("partner")
            ? partnerName.partner
            : showPlaceParnter
            ? showPlaceParnter
            : "";

          // https://stackoverflow.com/questions/22538473/leaflet-center-popup-and-marker-to-the-map
          // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/373
          // NOTE do we add this solution?
          // layer.setZIndexOffset(2000);
          layer.openPopup();
          // .on("popupopen", function (e) {
          // let px = map.project(e.popup._latlng);
          // px.y -= e.popup._container.clientHeight;
          // map.panTo(map.unproject(px));
          // })

          setTimeout(() => {
            AnalyticsTracking(`click${clickType}`, {
              name: name_tracking,
              icon,
              icon_shortcode: null,
              // lat: Number(latitude),
              // lng: Number(longitude),
              partner,
            });
          }, 1000);

          checkPlayes = true;
        }
      }

      if (layer._path) {
        let checkPolygon = false;
        if (
          layer._path.className.baseVal.search(`place-${placeID}`) !== -1 &&
          !checkPolygon
        ) {
          window[`area-${placeID}`].openPopup();
          checkPolygon = false;
        }
      }
    });
  }
}
