import CustomIcon from "./customIcon";
import AnalyticsTracking from "@helpers/analyticsTracking";

export default function AddTwitsToMap({ dataTwitsID, clickType }) {
  HQ.Properties.highlights?.map((obj) => {
    if (obj.id === dataTwitsID) {
      // console.log(obj);
      const {
        id,
        icon,
        color,
        color_front,
        color_outline,
        latitude,
        html,
        name_tracking,
        longitude,
      } = obj;

      if (window[`marker-${dataTwitsID}`] === undefined) {
        window[`marker-${dataTwitsID}`] = L.marker([latitude, longitude], {
          icon: CustomIcon({
            id,
            icon,
            color,
            color_front,
            color_outline,
          }),
        })
          .addTo(map)
          .bindPopup(html)
          .on("click", function (e) {
            const { lat, lng } = e.latlng;
            AnalyticsTracking("click_place", {
              name: name_tracking,
              icon,
              icon_shortcode: null,
              description: "click",
              // lat,
              // lng,
            });
          })
          .on("popupopen", function (e) {
            setTimeout(() => {
              AnalyticsTracking("click_highlight", {
                name: name_tracking,
                icon,
                icon_shortcode: null,
                description: "",
                // lat: latitude,
                // lng: longitude,
              });
            }, 1000);
          })
          .on("popupclose", function (e) {
            document.body.dataset.tweetOpened = "";
          })
          .openPopup();

        const videoID = window["resultsPlaces"]
          .filter(({ icon }) => icon === "icon-video")
          .map(({ id }) => id);

        window[`marker-${dataTwitsID}`].addTo(window[`video-${videoID[0]}`]);

        document.body.dataset.tweetOpened = dataTwitsID;
      } else {
        let checkPlayes = false;
        map.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            if (
              layer.options.icon.options.className.search(
                `place-${dataTwitsID}`
              ) !== -1 &&
              !checkPlayes
            ) {
              layer.openPopup();
              checkPlayes = true;
            }
          }
        });
      }
    }
  });
}
