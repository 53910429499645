import map from "@components/map";
import AnalyticsTracking from "@helpers/analyticsTracking";
import CustomIcon from "./customIcon";
import UpdateInformationLayerID from "./updateInformationLayerID";

export default function CheckingIfLayerAddedToMap(videoID, idLayer, showOnMap) {
  removeTwitterFromMapIfShowOnMapFalse(videoID, idLayer, showOnMap);

  if (window[`marker-${idLayer}`] != undefined) return;

  const newHighlightsObject = HQ.Properties.highlights.filter(
    ({ id }) => id === idLayer
  )[0];

  const {
    id,
    name_tracking,
    icon,
    html,
    description,
    icon_shortcode,
    latitude,
    longitude,
    show_on_map,
    color,
    color_front,
    color_outline,
  } = newHighlightsObject;

  let popupContent;
  let options;

  if (html) {
    popupContent = html;
    options = { minWidth: 240 };
  }

  if (latitude && show_on_map) {
    window[`marker-${id}`] = L.marker([latitude, longitude], {
      icon: CustomIcon({
        id,
        icon,
        content: icon_shortcode,
        color,
        color_front,
        color_outline,
      }),
    })
      .bindPopup(popupContent, options)
      .on("click", function (e) {
        const { lat, lng } = e.latlng;
        AnalyticsTracking("click_place", {
          name: name_tracking,
          icon,
          icon_shortcode,
          description,
          lat,
          lng,
        });

        // set center marker width popup
        let currZoom = map.getZoom();
        map.setView(e.target.getLatLng(), currZoom);
      });

    // add marker to video layer
    window[`marker-${id}`].addTo(window[`video-${videoID}`]);

    // update information children data-children=""
    // in information slider
    UpdateInformationLayerID(videoID, id);
  }
}

function removeTwitterFromMapIfShowOnMapFalse(videoID, idLayer, showOnMap) {
  const checkIfTweetOpened = document.body.dataset.tweetOpened;

  const checkIfMarkerOnMap =
    window[`marker-${idLayer}`] == undefined ? false : true;

  if ((showOnMap && checkIfMarkerOnMap) || idLayer == checkIfTweetOpened)
    return;

  try {
    window[`video-${videoID}`].removeLayer(window[`marker-${idLayer}`]);
    delete window[`marker-${idLayer}`];
  } catch (error) {}
}
