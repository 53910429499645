import { capitalize, clearei18n } from "@utils/function";
import { iconsType } from "./iconsType";
import { defaultInformationSettings } from "@helpers/defaults";

/**
 * Retirn a row for auto complete
 * @param {object} objectArrays
 * @returns
 */
export default function ShowInformationAboutPlacesRow(objectArrays) {
  const { allLayers, allGroups } = defaultInformationSettings;

  const newArrayObject =
    HQ.Properties.appType === "golf"
      ? [allLayers, allGroups, ...objectArrays]
      : [allLayers, ...objectArrays];

  return newArrayObject
    .map(({ id, icon, icon_shortcode, name, children }, index) => {
      let layerName = icon?.replace(/icon-/g, "");

      let { color, color_front, color_outline } = children[0] || {};

      color = color || "";
      color_front = color_front || "";
      color_outline = color_outline || "";

      // children id
      const childrenID = children.map((child) => child.id).sort();

      const childrenObj = children[0];
      let colorBackground = childrenObj?.color;
      colorBackground = colorBackground ? colorBackground : null;

      let template = "";

      const switcher = `
        <div class="switch-container">
          <label class="switch" for="checkbox">
            <input type="checkbox" name="checkbox-switcher" class="checkbox-switcher" id="checkbox-${id}" />
            <div class="swich-slider round"></div>
          </label>
        </div>
      `;

      // create row text
      let removeNumberFromName =
        icon === "icon-hole" ? name.replace(/[0-9]/g, "") : name;

      // set the color of the "Group" icon in "information",
      // if there is no--color - group variable, set the color to red
      const colorForFlights =
        icon === "icon-flights" ? "var(--color-group, #ff0000)" : color;

      // set layer id
      const typeOfMap = HQ.Properties.appType === "golf" ? 1 : 0;

      const layerId = index > typeOfMap ? `${layerName}-${id}` : layerName;

      // set type of icon
      const iconType =
        icon === "icon-restpoint"
          ? InfoRowIcon(
              icon,
              icon_shortcode,
              colorForFlights,
              color_front,
              color_outline
            )
          : InfoRowIcon(icon, layerName, colorForFlights, color_front);

      const trackingData = `${icon}, ${removeNumberFromName
        .toLowerCase()
        .trim()}`;

      template += `
        <div data-tracking="${trackingData}" class="d-flex align-items-center information-row" data-layer-id="${layerId}" data-children="${childrenID}">
          ${iconType}
          ${InfoRowName(capitalize(removeNumberFromName))}
          ${switcher}
        </div>`;

      return template;
    })
    .join("");
}

/**
 * Set icon - information sidebar
 *
 * @param {string} iconName
 * @param {string} layerName
 * @param {string} color
 * @returns
 */
function InfoRowIcon(iconName, layerName, color, color_front) {
  return `<div class="icon ${iconName} information-row-icon">${iconsType({
    icon: iconName,
    content: layerName,
    place: "information",
    color,
    color_front,
  })}</div>`;
}

/**
 * Set information row - information sidebar
 *
 * @param {string} layerName
 * @returns
 */
function InfoRowName(layerName) {
  const i18nData = clearei18n(layerName);

  const i18JSON = HQ.Properties.i18n["information"][i18nData];

  // console.log("i18JSON", i18JSON);

  const rowText = i18JSON ? i18JSON : layerName;

  return `<div class="information-row-name">${rowText}</div>`;
}
