import { select, body } from "@utils/function";

/**
 * Clear active slider and remove active class
 */
export default function CleareActiveSlider() {
  const activeSliderClassName = "active-slider";

  const activeSlider = select(`.${activeSliderClassName}`);
  activeSlider?.classList.remove(activeSliderClassName);

  const sliders = [
    "information-is-active",
    "players-is-active",
    "leaderboard-is-active",
    "location-is-active-error",
    "location-is-active",
    "highlights-is-active",
    "programme-is-active",
    "schedule-is-active",
    "results-is-active",
  ];

  sliders.forEach((slider) => {
    body.classList.remove(slider);
  });
}
