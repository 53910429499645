import { select, createElement, svgIcon } from "@utils/function";
import QuickBarConfig from "./quickBarConfig";
import SliderLayer from "./sliderLayer";
import { ios } from "./../../utils/function";

const quickBarConfig = QuickBarConfig();

// create slider
SliderLayer(quickBarConfig);

const template = quickBarConfig
  .map(({ icon, i18n, sortNumber, show }) => {
    if (sortNumber !== 0 && typeof sortNumber !== "undefined" && show) {
      return `
    <div class="menu-item flex-column justify-content-between flex-fill sidebar-${icon}">
      ${svgIcon({ name: `icon-${icon}` })}
      <div class="menu-text mt-1">${HQ.Properties.i18n.sidebar[i18n]}</div>
    </div>
  `;
    }
  })
  .join("");

// if iso add padding to quickbar
const paddingBottomIos = ios() ? "pb-2" : "";

const sidebar = createElement("div");
sidebar.className += `sidebar ${paddingBottomIos} d-flex justify-content-between`;
sidebar.insertAdjacentHTML("beforeend", template);

// set sidebar element after map
// create siidebar menu
const mapContainer = select("#slider-wrapper");
mapContainer.insertAdjacentElement("beforeend", sidebar);
