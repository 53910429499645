import { defaults } from "@helpers/defaults";
import { hasTouchScreen } from "@utils/function";
import ZoomOnMobileOrDesktop from "./zoomMobileOrDesktop";
import addZoomAtributei18n from "./zoomAttribute";
// https://github.com/GIAPspzoo/L.TileLayer.Canvas
import "./tileLayerCanvas";

let { latlng, latlng_mobile, maxZoom, minZoom } = HQ.Properties.mapCoordinates;

latlng_mobile = latlng_mobile == null ? latlng : latlng_mobile;

// https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/365
const [lat, lng] = (hasTouchScreen() ? latlng_mobile : latlng)
  .split(",")
  .map(Number);

// --------------------------------------------------
// generate map

const map = L.map("map", {
  center: [lat, lng],
  zoomControl: false, // zoom control off
  zoomDelta: 0.25,
  zoomSnap: 0.1,
  maxZoom,
  minZoom,
  zoom: ZoomOnMobileOrDesktop,
  tap: false,
  // attributionControl: false,
});

// --------------------------------------------------
// move zoom to top right

L.control.zoom({ position: "topright" }).addTo(map);

// --------------------------------------------------
// custom layer
// `https://tile.openstreetmap.org/{z}/{x}/{y}.png?key=${defaults.baseMapKey}`,

if (defaults.baseMapUrl) {
  const detectTypeOfMapsBing = defaults.baseMapUrl?.includes("bing");
  const detectTypeOfMapsAzure = defaults.baseMapUrl?.includes("azuremaps");

  if (detectTypeOfMapsBing) {
    const defaultsConfig = {
      key: defaults.baseMapKey,
      detectRetina: true,
    };

    L.bingLayer(
      L.extend({}, defaultsConfig, {
        imagerySet: "Aerial",
        style: HQ.Properties.styleBingMap,
        culture: "en",
      })
    ).addTo(map);
  } else if (detectTypeOfMapsAzure) {
    // https://github.com/Azure-Samples/azure-maps-leaflet
    let layer = new L.TileLayer.AzureMaps({
      authOptions: {
        authType: "subscriptionKey",
        subscriptionKey: defaults.baseMapKey,
      },
    });

    layer.setTilesetId(HQ.Properties.styleAzureMaps);

    map.addLayer(layer);

    //Use the static function for creating Azure Maps tile layer easily.
    // L.tileLayer.azureMaps({ authOptions }).addTo(map);
  } else {
    const configOption = {
      attribution: defaults.baseMapAttribution,
      maxZoom,
      minZoom,
    };
    L.tileLayer(
      `${defaults.baseMapUrl}?key=${defaults.baseMapKey}`,
      configOption
    ).addTo(map);
  }
}

// --------------------------------------------------
// Custom event layer
// plugion for canvas layer
// github.com/GIAPspzoo/L.TileLayer.Canvas

if (defaults.customLink) {
  window["defaults-map"] = L.tileLayer
    .canvas(`${defaults.customLink}`, {
      doubleSize: true,
      className: "golf-layer",
      attribution: defaults.customAttribution,
      crossOrigin: true,
      minZoom,
      maxZoom,
    })
    .addTo(map);
}

// set title for zoom in/out
addZoomAtributei18n();

window["map"] = map;

export default map;
