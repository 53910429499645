// #88 Add event tracking to map
// https://www.rudderstack.com/docs/stream-sources/rudderstack-sdk-integration-guides/rudderstack-javascript-sdk/#track

/**
 *
 * @param {string} Captures the name of the tracked event
 * @param {object} Tracks the event properties
 */
export default function AnalyticsTracking(event, properties) {
  let eventName = "events";
  switch (event) {
    case "location":
      eventName = "location";
      break;
    case "zoomOut":
    case "zoomIn":
    case "centerView":
      eventName = "zoom";
    default:
      break;
  }

  const prop = properties
    ? {
        tournament_partner: HQ.Properties.appSponsorTournamentPartner,
        presenting_partner: HQ.Properties.appSponsorPresentingPartner,
        watch_partner: HQ.Properties.appSponsorWatchPartner,
        other_partner: HQ.Properties.appSponsorOtherPartner,
        app_type: HQ.Properties.appType,
        app_id: HQ.Properties.appId,
        channel_id: HQ.Properties.channelID,
        eventname: event,
        ...properties,
      }
    : {
        tournament_partner: HQ.Properties.appSponsorTournamentPartner,
        presenting_partner: HQ.Properties.appSponsorPresentingPartner,
        watch_partner: HQ.Properties.appSponsorWatchPartner,
        other_partner: HQ.Properties.appSponsorOtherPartner,
        app_type: HQ.Properties.appType,
        app_id: HQ.Properties.appId,
        channel_id: HQ.Properties.channelID,
        eventname: event,
      };

  try {
    rudderanalytics.track(eventName, prop, () => {
      // console.table({ eventType: event, properties: prop });
    });
  } catch (error) {}
}
