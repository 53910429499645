import { createElement, select, svgIcon } from "@utils/function";

// --------------------------------------------------
// sidebar information

export default function SliderLayer(QuickBarConfig) {
  const checkIfParnterQuickBar = HQ.Properties.appSponsorQuickbarPartnerLogo;

  let columnClose = checkIfParnterQuickBar ? "col-1" : "col-3";

  const leaderboardRoundInformation =
    typeof HQ.Properties.currentRound !== "undefined"
      ? ` - <span>${
          HQ.Properties.i18n["slider"].round
        }</span><span class="round-number">${
          +HQ.Properties.currentRound > 0 ? HQ.Properties.currentRound : ""
        }</span>`
      : "";

  const mapContainer = select("#slider-wrapper");

  QuickBarConfig.map(({ icon, i18n, title, className, sortNumber, show }) => {
    if (sortNumber === 0 || (typeof sortNumber == "undefined" && show)) return;

    const slider = createElement("div");
    const content = slider.cloneNode(false);
    slider.className = `${className} slider`;

    // wrapper for logo | text | logo partner | close button x
    const sliderWrapper = createElement("div");
    sliderWrapper.className = `d-flex gap-1 justify-content-between slider-wrapper-content slider-title ${className}-title`;
    slider.appendChild(sliderWrapper);

    // logo column
    const logoColumn = createElement("div");
    logoColumn.className =
      "d-flex align-items-center h-100 logo-slider quickbar-brand";
    logoColumn.append(GetImageFromTop());

    // add logo to slider
    sliderWrapper.appendChild(logoColumn);

    // title slider
    const createWrapperTitle = createElement("div");
    createWrapperTitle.className = "title-slider text-center col";
    const titleElement = `<span class="slider-name">${HQ.Properties.i18n.slider[i18n]}</span>`;
    const titleContent =
      className === "leaderboard-content" || className === "players-content"
        ? `${titleElement}${leaderboardRoundInformation}`
        : titleElement;
    createWrapperTitle.insertAdjacentHTML("beforeend", titleContent);

    sliderWrapper.appendChild(createWrapperTitle);

    content.classList.add("slider-content");

    // parnter logo
    if (checkIfParnterQuickBar) {
      const partnerInQuckbar = createElement("div");
      partnerInQuckbar.className = "parnter-quickbar-slider text-center col-3";

      const createImageQuickbarPartner = createElement("img");
      createImageQuickbarPartner.src = checkIfParnterQuickBar;
      createImageQuickbarPartner.classList.add("img-fluid");

      partnerInQuckbar.appendChild(createImageQuickbarPartner);
      sliderWrapper.appendChild(partnerInQuckbar);
    }

    // close button
    const buttonCloseWrapper = createElement("div");
    buttonCloseWrapper.className = `button-close-slider d-flex justify-content-end ${columnClose}`;
    const buttonClose = createElement("button");
    buttonClose.classList.add("close-slider");
    buttonClose.setAttribute("aria-label", title);
    buttonClose.insertAdjacentHTML(
      "beforeend",
      svgIcon({ className: "icon", name: "icon-close" })
    );

    buttonCloseWrapper.append(buttonClose);
    sliderWrapper.appendChild(buttonCloseWrapper);

    slider.appendChild(content);

    mapContainer.insertAdjacentElement("afterbegin", slider);
  });
}

/**
 * Create image element
 * @returns HTMLImageElement
 */
function GetImageFromTop() {
  const image = document.body.dataset.logo;

  const createImage = createElement("img");
  createImage.src = image;
  createImage.classList.add("img-fluid");
  createImage.alt = "logo";

  return createImage;
}
