import map from "@components/map";
import CustomIcon from "./customIcon";
import AnalyticsTracking from "@helpers/analyticsTracking";
import { defaults } from "@helpers/defaults";

const zoomLevelDefault = Math.floor(map.getZoom());
const checkIfTypeSiteIsBackend =
  HQ.Properties.typeSite === "backend" ? true : false;

/**
 * Add icons to map
 * @param {object} arrayPlaces
 */
export default function AddIconsToMap(arrayPlaces) {
  arrayPlaces.map(({ id, icon, children }) => {
    let layerName = icon?.replace(/icon-/g, "");

    // check query string in url
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const placeID = urlParams.get("place");

    const layerNameWithId = `${layerName}-${id}`;

    window[layerNameWithId] = L.featureGroup().addTo(map);

    if (checkIfTypeSiteIsBackend) {
      window[layerNameWithId].addTo(map);
    }
    children.map(
      ({
        id,
        name,
        name_tracking,
        hole,
        icon,
        html,
        description,
        icon_shortcode,
        latitude,
        longitude,
        area,
        lft,
        color,
        color_front,
        zoom_levels,
        color_outline,
        partner,
      }) => {
        let popupContent;
        let options;

        const partnerName = partner ? { partner: partner["name"] } : "";

        // console.log("id", id);
        const checkIfZoomLevelIsInArray =
          zoom_levels.includes(zoomLevelDefault);
        // console.log(checkIfZoomLevelIsInArray);

        if (icon === "icon-hole") {
          let { avg_score, metres, no, par, yards } = hole;

          avg_score = avg_score
            ? `<div class="hole-info-avg">Avg. score: ${avg_score}</div>`
            : "";

          const htmlData = html
            ? `<div class="hole-info-html pb-2">${html}</div>`
            : "";

          popupContent = `
          <div class="hole-info">
            ${htmlData}
            <div class="hole-info-par"><strong>${HQ.Properties.i18n["popupContent"]["hole"]} ${no}</strong> PAR ${par}</div>
            <div class="hole-info-units">${metres}m/${yards}y</div>
            ${avg_score}
          </div>`;
        } else {
          if (html) {
            popupContent = html;
            options = { minWidth: 240 };
          } else if (description) {
            options = { minWidth: 240 };
            popupContent = `${name}<br>${description}`;
          } else {
            options = "";
            popupContent = name;
          }
        }

        if (latitude) {
          const contentDescription =
            icon === "icon-hole"
              ? {
                  content: icon_shortcode,
                  iconConfig: defaults.iconSizeForHole,
                }
              : { content: icon_shortcode };

          window[`marker-${id}`] = L.marker([latitude, longitude], {
            icon: CustomIcon({
              id,
              icon,
              ...contentDescription,
              color,
              color_front,
              color_outline,
              partner,
            }),
          })
            .bindPopup(popupContent, options)
            .on("click", function (e) {
              const clickType = icon == "icon-video" ? "Highlight" : "Place";

              AnalyticsTracking(`click${clickType}`, {
                name: name_tracking,
                icon,
                icon_shortcode,
                description,
                ...partnerName,
              });
            });
          // NOTE: temporary off
          // .on("popupopen", function (e) {
          //   let px = map.project(e.popup._latlng);
          //   px.y -= e.popup._container.clientHeight / 2;
          //   map.panTo(map.unproject(px));
          //   console.log("one");
          // });

          // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/373
          // set zIndex depence on lft
          zIndexChange(lft, latitude, longitude, id);

          // check if zoom_levels contains
          // the current zoom level on map
          // if true add all icon to the map
          if (checkIfZoomLevelIsInArray) {
            // if crawler exist show only one place (twslider-wrapperitter - highlights)
            if (HQ.Properties.isSocialMediaBot) {
              // if id === placeID then show this highlights
              if (id == placeID) {
                window[`marker-${id}`].addTo(window[layerNameWithId]);
              } else if (icon !== "icon-video") {
                // show all icons except icon-video
                window[`marker-${id}`].addTo(window[layerNameWithId]);
              }
            } else {
              window[`marker-${id}`].addTo(window[layerNameWithId]);
            }
          }

          if (checkIfTypeSiteIsBackend) {
            window[`marker-${id}`].addTo(window[layerNameWithId]);
          }
        }

        if (area) {
          addAreaToLayer({
            checkIfZoomLevelIsInArray,
            area,
            layerNameWithId,
            id,
            name_tracking,
            icon,
            icon_shortcode,
            description,
            popupContent,
          });
        }
      }
    );
  });
}

/**
 * Add area to layer
 *
 * @param {object} param
 */
function addAreaToLayer({
  checkIfZoomLevelIsInArray,
  area,
  layerNameWithId,
  id,
  name_tracking,
  icon,
  icon_shortcode,
  description,
  popupContent,
}) {
  window[`area-${id}`] = geoJSONLayer({
    id,
    name: name_tracking,
    icon,
    icon_shortcode,
    description,
    area: JSON.parse(area),
    popupContent,
  });
  // check if zoom_levels contains
  // the current zoom level on map
  // if true add all polygon to the map
  if (checkIfZoomLevelIsInArray) {
    window[`area-${id}`].addTo(window[layerNameWithId]);
  }

  if (checkIfTypeSiteIsBackend) {
    window[`area-${id}`].addTo(window[layerNameWithId]);
  }
}

/**
 * Add layer geoJSON to layer
 *
 * @param {object} param
 * @returns geoJSON
 */
function geoJSONLayer({
  id,
  name_tracking,
  icon,
  icon_shortcode,
  description,
  area,
  popupContent,
  color = "#1E88E5",
}) {
  const dashArray = icon === "icon-hole" && area ? 3 : 0;

  const geoJsonLayers = L.geoJSON(area, {
    style: function (feature) {
      color =
        icon === "icon-hole" && area
          ? feature.properties.color || "#ffffff"
          : feature.properties.color || color;
      return {
        color,
        weight: 2,
        dashArray,
        fillOpacity: feature.properties.fillOpacity || 1,
        className: id ? `place-${id}` : "",
      };
    },
    onEachFeature: function (feature, layer) {
      layer.on("click", function (e) {
        AnalyticsTracking("clickPlace", {
          name: name_tracking,
          icon,
          icon_shortcode,
          description,
        });
      });
    },
  }).bindPopup(popupContent);

  if (checkIfTypeSiteIsBackend) {
    geoJsonLayers.addTo(map);
  }

  return geoJsonLayers;
}

/**
 * The change of zIndex depends on the lft variable
 *
 * @param {Number} lft
 * @param {Number} lat
 * @param {Number} lng
 * @param {Number} markerId
 */
function zIndexChange(lft, lat, lng, markerId) {
  const pos = map.latLngToLayerPoint(new L.latLng(lat, lng)).round();
  const removeDefaultzIndex = pos.y < 0 ? pos.y : -pos.y;
  window[`marker-${markerId}`].setZIndexOffset(
    removeDefaultzIndex + 1000 - lft
  );
}
